import { TextAndImage } from '@cointracker/styleguide';
import { Grid } from '@cointracker/styleguide/src/Grid';
import { Body, Heading } from '@cointracker/styleguide/src/LoggedIn';
import { Button } from '@cointracker/styleguide/src/LoggedIn/Button';
import { useSearchParams } from '@cointracker/ui';
import { trackSignUpButtonClicked } from 'src/common/analytics';
import { usePartnerStore } from 'src/pages-react/landing/partnerStore';
import { buildGetStartedUrl, getNextUrl } from 'src/pages-react/landing/utils';
import { textAndImage } from './PartnerFeature.css';

export interface PartnerFeatureProps {
  title: string;
  subtitles: string[];
  disclaimerText?: string;
  heroImageUrl: string;
  learnMoreLink?: string;
  sublist?: string[];
  buttonText?: string;
  toggleModal?: () => void;
  belowListText?: string;
}

export const PartnerFeature = ({
  title,
  subtitles,
  heroImageUrl,
  buttonText,
  toggleModal,
  sublist,
  disclaimerText,
  belowListText,
}: PartnerFeatureProps) => {
  const [searchParams] = useSearchParams();
  const { partner } = usePartnerStore();
  const nextUrl = getNextUrl(searchParams, partner);
  const getStartedUrl = buildGetStartedUrl(nextUrl);

  const trackCoinbaseSignup = () => {
    trackSignUpButtonClicked(
      buttonText || 'Start for free',
      `${partner?.slug}-promotion`,
      title,
      title,
    );
  };
  const handleButtonClick = () => {
    if (toggleModal) {
      toggleModal();
    } else {
      trackCoinbaseSignup();
      window.location.href = getStartedUrl;
    }
  };

  return (
    <Grid>
      <TextAndImage
        imgSrc={heroImageUrl}
        imgAlt={heroImageUrl}
        className={textAndImage}
      >
        <div className="flex max-w-[530px] flex-col gap-16">
          <Heading variant="h3" className="text-wrap">
            {title}
          </Heading>
          {subtitles.map((subtitle, index) => (
            <Body
              className="text-text-secondary first-of-type:font-bold"
              key={index}
            >
              {subtitle}
            </Body>
          ))}
          <ul className="max-w-[465px] list-outside list-decimal pl-24 text-text-secondary [&>li::marker]:text-18 [&>li::marker]:leading-150 [&>li::marker]:text-slate-40">
            {sublist?.map((item, index) => (
              <li key={index}>
                <Body className="text-text-secondary">{item}</Body>
              </li>
            ))}
          </ul>
          {belowListText ? (
            <Body className="mt-8 text-text-secondary">{belowListText}</Body>
          ) : (
            <Body className="text-text-secondary" variant="body5">
              {disclaimerText ??
                'Offer valid for new customers only. Expires Dec 31, 2025.'}
            </Body>
          )}
        </div>

        <Button
          variant="line"
          className="mb-16 mt-32 md:mb-0"
          onClick={handleButtonClick}
        >
          {buttonText}
        </Button>
      </TextAndImage>
    </Grid>
  );
};
