import fileInMinutesJSON from '@/assets/rebrand/lottie/1 - File in minutes.js';
import complexityToClarityJSON from '@/assets/rebrand/lottie/2 - Complexity to clarity.js';
import comprehensiveCoverageJSON from '@/assets/rebrand/lottie/3 - Comprehensive coverage.js';
import saveMoneyBoostWealthJSON from '@/assets/rebrand/lottie/4 - Save money, boost wealth.js';
import { CreditBlock } from '@/components/Rebrand/CreditBlock';
import { CallToActionButton } from '@/components/Rebrand/LandingCTAs';
import {
  Accordion,
  Banner,
  Body2,
  Body3,
  CTABlockContainer,
  CTAPrefixTitle,
  isClient,
  LANDING_SOCIAL_TESTIMONIALS,
  LandingImage,
  PartnersLanding,
  SocialTestimonialBlock,
  Ticker,
} from '@cointracker/styleguide';
import {
  ListItem,
  UnorderedList,
} from '@cointracker/styleguide/src/AccordionModule/List';
import { LandingCTATitle } from '@cointracker/styleguide/src/CTABlock/CTABlock';
import { Grid } from '@cointracker/styleguide/src/Grid';
import { usePushFade } from '@cointracker/styleguide/src/hooks/usePushFade';
import coinbaseIconUrl from '@cointracker/styleguide/src/icons/partners/Coinbase.svg?url';
import hrBlockIconUrl from '@cointracker/styleguide/src/icons/partners/H&R Block.svg?url';
import intuitIconUrl from '@cointracker/styleguide/src/icons/partners/intuit-turbo-tax-squared.svg?url';
import openseaIconUrl from '@cointracker/styleguide/src/icons/partners/OpenSea.svg?url';
import phantomIconUrl from '@cointracker/styleguide/src/icons/partners/Phantom.svg?url';
import solanaIconUrl from '@cointracker/styleguide/src/icons/partners/Solana Foundation.svg?url';
import uniswapIconUrl from '@cointracker/styleguide/src/icons/partners/Uniswap.svg?url';
import { Heading } from '@cointracker/styleguide/src/LoggedIn';
import { COLORS } from '@cointracker/styleguide/src/themePrimitives.css';
import { REBRAND_URLS, URLS, useSearchParams } from '@cointracker/ui';
import { CalendarCheck } from '@phosphor-icons/react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { useEffect, useRef, useState } from 'react';
import headerUrl from 'src/assets/rebrand/landing-header-unoptimized.webp?url';
import Soc1Icon from 'src/assets/rebrand/soc-1-type-2-light.svg?react';
import Soc2Icon from 'src/assets/rebrand/soc-2-type-2-light.svg?react';
import { trackSignUpButtonClicked } from 'src/common/analytics';
import { useGate } from 'src/hooks/statsig';
import { useAuth } from 'src/hooks/useAuth';
import { usePartnerStore } from 'src/pages-react/landing/partnerStore';
import {
  determineDiscountText,
  getPartner,
  processPartnerSpecificURLParams,
  setCookies,
} from 'src/pages-react/landing/utils';
import { CBO_PROMO_KEY } from '../../../pages-react/CoinbaseOnePromotionPage/CoinbaseOnePromotionPage/constants';
import { FAQAccordion } from '../FAQAccordion';
import {
  LandingFeature,
  LandingFeatureButton,
  LandingFeatureContainer,
  LandingFeatureSubtitle,
  LandingFeatureTitle,
} from '../LandingFeature';
import { useFeatureState } from '../LandingFeature/utils';
import { CoinbaseOneModal } from '../Partner/CoinbaseOneModal';
import { useBackgroundFade } from './hooks/useBackgroundFade';
import {
  landingHeaderContainer,
  landingImageBlock,
  partnersContainer,
  securityLogos,
} from './LandingFlow.css';
import { LandingHeaderCopy } from './LandingHeaderCopy';
import { getPartnerFeature } from './utils';
const FEATURES_SECTIONS = ['Speed', 'Ease', 'Accuracy', 'Growth'];
const TAX_COUNT_DOWN_BANNER_KEY = '2025-tax-countdown-banner';

interface LandingFlowProps {
  partnerSlug?: string;
}

export const LandingFlow = (props: LandingFlowProps) => {
  const { partnerSlug } = props;

  const [searchParams] = useSearchParams();
  const { setPartner } = usePartnerStore();
  const { isAuthenticated } = useAuth();
  const isCoinbaseOne = partnerSlug === 'coinbaseone';
  const hasPromoKeyInStorage =
    isClient() && Boolean(localStorage.getItem(CBO_PROMO_KEY));
  const hasPromoKeyInURL = searchParams.has('c');
  const shouldAutoPop =
    isCoinbaseOne && (hasPromoKeyInStorage || hasPromoKeyInURL);
  const [isOpen, setIsOpen] = useState(shouldAutoPop);
  const [isBannerOpen, setIsBannerOpen] = useState(true);
  const [isUSUser, setIsUSUser] = useState(true);

  useEffect(() => {
    try {
      const locale = navigator.language || 'en-US';
      const country = locale.split('-')[1] || 'US';
      setIsUSUser(country === 'US');
    } catch (e) {
      console.error('Error determining country:', e);
      setIsUSUser(true);
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem(TAX_COUNT_DOWN_BANNER_KEY) === 'false') {
      setIsBannerOpen(false);
    }

    if (!isBannerOpen) {
      localStorage.setItem(TAX_COUNT_DOWN_BANNER_KEY, 'false');
    }
  }, [isBannerOpen]);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };
  const { value: earlyBirdDiscount } = useGate('early_bird_discount_2025');

  useEffect(() => {
    if (partnerSlug) {
      const partner = getPartner(partnerSlug);
      if (!partner) return;

      processPartnerSpecificURLParams(partnerSlug, searchParams);
      partner.userId = searchParams.get('partner_id') ?? undefined;
      setPartner(partner);
      setCookies(partner, searchParams.get('utm_source') ?? undefined);
      if (isAuthenticated) {
        window.location.href = `${URLS.HOME_PAGE}${window.location.search}`;
      }
    }
  }, [searchParams, setPartner, partnerSlug, isAuthenticated]);

  const partnerFeature = getPartnerFeature(
    partnerSlug,
    earlyBirdDiscount,
    toggleModal,
  );

  const landingHeaderCopyRef = useRef<HTMLDivElement>(null);
  const landingImageRef = useRef<HTMLDivElement>(null);
  const securityRef = useRef<HTMLDivElement>(null);
  const creditBlockRef = useRef<HTMLDivElement>(null);
  const testimonialRef = useRef<HTMLDivElement>(null);
  const bottomCTARef = useRef<HTMLDivElement>(null);

  const { ref: fileInMinutesRef, state: fileInMinutesState } =
    useFeatureState();
  const { ref: complexityToClarityRef, state: complexityToClarityState } =
    useFeatureState();
  const { ref: comprehensiveCoverageRef, state: comprehensiveCoverageState } =
    useFeatureState();
  const { ref: saveMoneyBoostWealthRef, state: saveMoneyBoostWealthState } =
    useFeatureState();
  usePushFade(creditBlockRef.current);

  useBackgroundFade();
  usePushFade(securityRef.current);
  usePushFade(bottomCTARef.current, {
    ease: 'cointrackerBounce',
    duration: 0.4,
    interval: 0.45,
    delay: 0.45,
    animateContainer: true,
  });

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    // Landing copy
    const tlLandingHeader = gsap.timeline({
      scrollTrigger: {
        trigger: landingImageRef.current,
        start: 0,
        end: 300,
        scrub: true,
      },
    });
    tlLandingHeader.fromTo(
      landingHeaderCopyRef.current,
      {
        autoAlpha: 1,
      },
      {
        autoAlpha: 0,
      },
    );

    return () => {
      tlLandingHeader.kill();
    };
  }, []);

  const discountText = determineDiscountText({
    earlyBirdDiscount,
  });
  const showTicker = earlyBirdDiscount && discountText;

  return (
    <div>
      {isBannerOpen && isUSUser && (
        <Banner
          type="success"
          className="rounded-none py-8 text-center text-text-negative-foreground"
          withPrefixIcon={false}
          onBannerClose={() => {
            setIsBannerOpen(false);
          }}
        >
          <div className="flex flex-col items-center justify-center md:flex-row">
            <span className="flex items-center justify-center gap-6">
              <CalendarCheck className="-mt-1" height={18} width={18} />
              April 15 is coming fast—
            </span>
            <span className="flex items-center justify-center gap-6">
              make crypto taxes stress free!{' '}
              <a
                href={REBRAND_URLS.GET_STARTED}
                className="underline"
                onClick={() =>
                  trackSignUpButtonClicked(
                    'Start now',
                    'landing-banner',
                    'banner',
                    'homepage',
                  )
                }
              >
                Start now
              </a>
            </span>
          </div>
        </Banner>
      )}
      <CoinbaseOneModal toggleModal={toggleModal} isOpen={isOpen} />
      {showTicker && (
        <Ticker className="absolute w-full">{discountText}</Ticker>
      )}
      <div className={landingHeaderContainer} ref={landingHeaderCopyRef}>
        <LandingHeaderCopy
          partnerSlug={partnerSlug}
          toggleModal={toggleModal}
        />
      </div>

      <div>
        {!partnerSlug && (
          <Grid className={landingImageBlock}>
            <div ref={landingImageRef}>
              <LandingImage
                imgSrc={headerUrl}
                imgAlt="Futuristic abstract design with layered panels, floating shapes, and a figure interacting with a glowing interface"
              />
            </div>
          </Grid>
        )}

        {partnerSlug && <div ref={landingImageRef}>{partnerFeature}</div>}
        <div className={partnersContainer}>
          <PartnersLanding
            title="Official partners"
            topPartners={[
              { logoUrl: coinbaseIconUrl, text: 'Coinbase' },
              { logoUrl: intuitIconUrl, text: 'Intuit TurboTax' },
              { logoUrl: hrBlockIconUrl, text: 'H&R Block' },
              { logoUrl: uniswapIconUrl, text: 'Uniswap' },
            ]}
            bottomPartners={[
              { logoUrl: openseaIconUrl, text: 'Open Sea' },
              { logoUrl: phantomIconUrl, text: 'Phantom' },
              { logoUrl: solanaIconUrl, text: 'Solana Foundation' },
            ]}
          />
        </div>
      </div>

      <LandingFeatureContainer>
        <div ref={fileInMinutesRef}>
          <LandingFeature
            sections={FEATURES_SECTIONS}
            sectionIndex={0}
            imgAlt="File in minutes"
            lottieData={fileInMinutesJSON}
            snapState={fileInMinutesState}
            isLottie
            noFadeIn
          >
            <LandingFeatureTitle>
              File crypto taxes in minutes
            </LandingFeatureTitle>
            <LandingFeatureSubtitle>
              Easily import your crypto transactions and file your taxes in
              minutes with TurboTax, H&R Block, or your own tax professional.
              Save time and maximize your refund using our crypto tax software.
            </LandingFeatureSubtitle>
            <LandingFeatureButton
              onClick={() => {
                trackSignUpButtonClicked(
                  'Get started',
                  'landing-feature',
                  'File in minutes',
                  'homepage',
                );
                window.location.href = REBRAND_URLS.GET_STARTED;
              }}
            >
              Get started
            </LandingFeatureButton>
          </LandingFeature>
        </div>
        <div ref={complexityToClarityRef}>
          <LandingFeature
            sections={FEATURES_SECTIONS}
            sectionIndex={1}
            imgAlt="Clarity, not complexity"
            lottieData={complexityToClarityJSON}
            snapState={complexityToClarityState}
            isLottie
          >
            <LandingFeatureTitle>
              Simplify your crypto tax filing
            </LandingFeatureTitle>
            <LandingFeatureSubtitle>
              View all your crypto activity in one place and get a clear picture
              of your balance, performance, and taxes — automatically with our
              cryptocurrency tracker
            </LandingFeatureSubtitle>
            <LandingFeatureButton
              onClick={() => {
                trackSignUpButtonClicked(
                  'See more',
                  'landing-feature',
                  'Clarity, not complexity',
                  'homepage',
                );
                window.location.href = REBRAND_URLS.GET_STARTED;
              }}
            >
              See more
            </LandingFeatureButton>
          </LandingFeature>
        </div>
        <div ref={comprehensiveCoverageRef}>
          <LandingFeature
            sections={FEATURES_SECTIONS}
            sectionIndex={2}
            imgAlt="Comprehensive coverage"
            lottieData={comprehensiveCoverageJSON}
            snapState={comprehensiveCoverageState}
            isLottie
          >
            <LandingFeatureTitle>
              Accurate crypto tax calculations
            </LandingFeatureTitle>
            <LandingFeatureSubtitle>
              Our crypto tax software automatically categorizes DeFi
              transactions, removes spam, and integrates with 10,000 crypto
              assets to ensure your taxes are done right
            </LandingFeatureSubtitle>
            <LandingFeatureButton
              onClick={() => {
                trackSignUpButtonClicked(
                  'Get the full picture',
                  'landing-feature',
                  'Comprehensive coverage',
                  'homepage',
                );
                window.location.href = REBRAND_URLS.GET_STARTED;
              }}
            >
              Get the full picture
            </LandingFeatureButton>
          </LandingFeature>
        </div>
        <div ref={saveMoneyBoostWealthRef}>
          <LandingFeature
            sections={FEATURES_SECTIONS}
            sectionIndex={3}
            imgAlt="Save money, boost wealth"
            lottieData={saveMoneyBoostWealthJSON}
            snapState={saveMoneyBoostWealthState}
            isLottie
            noFadeOut
          >
            <LandingFeatureTitle>Save money, boost wealth</LandingFeatureTitle>
            <LandingFeatureSubtitle>
              Get more money back with personalized insights, tax-loss
              harvesting, and performance tracking tools powered by our crypto
              tax services
            </LandingFeatureSubtitle>
            <LandingFeatureButton
              onClick={() => {
                trackSignUpButtonClicked(
                  'Track portfolio',
                  'landing-feature',
                  'Save money, boost wealth',
                  'homepage',
                );
                window.location.href = REBRAND_URLS.GET_STARTED;
              }}
            >
              Track portfolio
            </LandingFeatureButton>
          </LandingFeature>
        </div>
      </LandingFeatureContainer>

      <div data-bg-color={COLORS.blue[20]}>
        <CTABlockContainer noBackground>
          <div ref={creditBlockRef}>
            <LandingCTATitle>
              <div>Making crypto taxes</div>
              <div>pain-free since 2017</div>
            </LandingCTATitle>
            <div data-push-fade-item>
              <CreditBlock />
            </div>
          </div>
        </CTABlockContainer>
      </div>

      <div ref={securityRef} data-bg-color={COLORS.lilac[10]}>
        <CTABlockContainer noBackground>
          <CTAPrefixTitle>Security at every step</CTAPrefixTitle>
          <LandingCTATitle>
            Customers around the
            <br />
            world trust us to keep their
            <br />
            data secure
          </LandingCTATitle>
          <Body2
            variant="secondary"
            className="text-center [&&]:ml-auto [&&]:mr-auto [&&]:mt-8 [&&]:max-w-[480px]"
          >
            With read-only access to your wallets, end-to-end encryption, and
            token-based two-factor authentication, your data is in safe hands
          </Body2>
          <div className={securityLogos} data-push-fade-item>
            <Soc1Icon />
            <Soc2Icon />
          </div>
        </CTABlockContainer>
      </div>

      <div ref={testimonialRef} data-bg-color={COLORS.lime[10]}>
        <SocialTestimonialBlock
          title="Don't just take our word for it"
          subtext="What our customers are saying"
          testimonials={LANDING_SOCIAL_TESTIMONIALS}
          fullscreen
          animated
        />
      </div>

      <div data-bg-color="white">
        <FAQAccordion>
          <Accordion.Item
            value="tax-gains"
            headline="Do you have to pay taxes on crypto gains?"
          >
            <Body3 variant="secondary">
              Yes, the IRS enforces capital gains tax on cryptocurrencies by
              classifying them as property, not currency. This means crypto
              sales, trades, or purchases are taxed under standard investment
              tax rules—either as short-term gains (taxed as income) or
              long-term gains (lower tax rates if held {`&gt;`}1 year).
            </Body3>
          </Accordion.Item>

          <Accordion.Item
            value="report-crypto"
            headline="How do you report crypto on taxes?"
          >
            <Body3 variant="secondary">
              To report crypto on taxes, track all taxable events, such as
              selling, trading, or earning crypto. Capital gains go on{' '}
              <a
                href="/blog/what-tax-forms-should-crypto-holders-file"
                target="_blank"
                className="underline"
              >
                Form 8949 & Schedule D
              </a>
              , while earned crypto is reported as income. Keep detailed
              records, and consider using crypto tax software for accuracy.
            </Body3>
          </Accordion.Item>

          <Accordion.Item
            value="report-if-not-sold"
            headline="Do you have to report crypto on taxes if you don't sell?"
          >
            <Body3 variant="secondary">
              No, if you only buy and hold crypto, you don&apos;t need to report
              it. However, if you earn crypto (from staking, mining, airdrops,
              or payments), it&apos;s considered taxable income and must be
              reported, even if you don&apos;t sell.
            </Body3>
          </Accordion.Item>

          <Accordion.Item
            value="when-to-pay"
            headline="When do you pay taxes on crypto?"
          >
            <Body3 variant="secondary">
              In general, there are four major taxable events you must include
              when{' '}
              <a
                href="/blog/crypto-tax-guide"
                target="_blank"
                rel="noopener noreferrer"
                className="underline"
              >
                filing your crypto taxes
              </a>
              :
            </Body3>
            <UnorderedList variant="secondary">
              <ListItem>
                Selling cryptocurrency for a fiat currency (e.g., USD or EUR)
              </ListItem>
              <ListItem>
                Trading cryptocurrency for other digital assets
              </ListItem>
              <ListItem>Using cryptocurrency to buy goods or services</ListItem>
              <ListItem>
                Receiving cryptocurrency from a hard fork upgrade or passive
                income opportunities like mining, staking, or yield farming
              </ListItem>
            </UnorderedList>
          </Accordion.Item>

          <Accordion.Item
            value="tax-amount"
            headline="How much tax do you have to pay on crypto?"
          >
            <Body3 variant="secondary">
              Crypto tax rates depend on how long you hold it and your income
              bracket:
            </Body3>
            <UnorderedList variant="secondary">
              <ListItem>
                Short-term capital gains (held ≤1 year) are taxed as ordinary
                income (10%–37% in the U.S.).
              </ListItem>
              <ListItem>
                Long-term capital gains (held {`&gt;`}1 year) have lower rates
                (0%, 15%, or 20%).
              </ListItem>
              <ListItem>
                Crypto earned (from mining, staking, or payments) is taxed as
                income at your regular tax rate.
              </ListItem>
            </UnorderedList>
          </Accordion.Item>

          <Accordion.Item
            value="tax-software"
            headline="How does tax software help with my crypto taxes?"
          >
            <Body3 variant="secondary">
              Crypto tax software like CoinTracker automates transaction
              tracking, calculates capital gains/losses, and generates tax forms
              (
              <a
                href="/blog/what-tax-forms-should-crypto-holders-file"
                target="_blank"
                className="underline"
              >
                Form 8949 & Schedule D
              </a>
              ). It syncs with exchanges and wallets, reducing errors and
              simplifying compliance—saving you time during tax season.
            </Body3>
          </Accordion.Item>
        </FAQAccordion>
        <div className="-mt-48 w-full justify-center px-16 text-center">
          <span>
            Want to learn more about 2025 crypto taxes?{' '}
            <a
              href="/blog/crypto-tax-guide"
              target="_blank"
              className="underline"
            >
              Explore our blog
            </a>
          </span>
        </div>
      </div>
      <div data-bg-color={COLORS.lime[20]}>
        <CTABlockContainer
          noBackground
          className="[&&]:min-h-[650px]"
          fullscreen
        >
          <div ref={bottomCTARef}>
            <Heading
              variant="h1"
              as="h2"
              className="flex flex-col place-items-center justify-center gap-4 text-center"
            >
              <span>Save time.</span>
              <span data-push-fade-item>Save money.</span>
            </Heading>
            <div data-push-fade-item>
              <Heading variant="h5" className="mt-24 max-w-[592px] text-center">
                Join the 2.5M+ people who trust CoinTracker with their crypto
                taxes
              </Heading>
              <div className="text-center">
                <CallToActionButton
                  href={REBRAND_URLS.GET_STARTED}
                  buttonText="Start for free"
                  pageTitle="consumer landing"
                  sectionName="SaveTimeMoneyCTA"
                  sectionTitle="Save time. Save money."
                >
                  Start for free
                </CallToActionButton>
              </div>
            </div>
          </div>
        </CTABlockContainer>
      </div>
    </div>
  );
};
