import TurboTaxIcon from '@cointracker/styleguide/src/icons/partners/Intuit TurboTax.svg?react';
import BinanceIcon from '@cointracker/styleguide/src/icons/wallets/Binance.svg?react';
import CoinbaseIcon from '@cointracker/styleguide/src/icons/wallets/Coinbase.svg?react';
import HRBlockIcon from '@cointracker/styleguide/src/icons/wallets/H&R Block.svg?react';
import KrakenIcon from '@cointracker/styleguide/src/icons/wallets/Kraken.svg?react';
import MetamaskIcon from '@cointracker/styleguide/src/icons/wallets/Metamask.svg?react';
import PhantomIcon from '@cointracker/styleguide/src/icons/wallets/Phantom.svg?react';
import { FileCheck, FileSearch } from 'lucide-react';
import { Body2 } from '../../LoggedIn';
import { cn } from '../../utils';

export const DecorativeRow = ({
  className,
  partnerSlug,
}: {
  className?: string;
  partnerSlug?: string;
}) => (
  <div className={cn('flex flex-col items-center gap-y-12 py-8', className)}>
    <div className="flex flex-wrap justify-center gap-x-24 gap-y-12">
      <div className="flex items-center gap-8">
        <div className="flex">
          <CoinbaseIcon className="h-30 w-30 rounded-full" />
          <BinanceIcon className="h-30 w-30 -ml-6 rounded-full" />
          <KrakenIcon className="h-30 w-30 -ml-6 rounded-full" />
          <MetamaskIcon className="h-30 w-30 -ml-6 rounded-full" />
          <PhantomIcon className="h-30 w-30 -ml-6 rounded-full" />
        </div>
        <Body2 className="text-text-secondary">500+ crypto integrations</Body2>
      </div>
      <TaxPartnerRow partnerSlug={partnerSlug} />
    </div>
  </div>
);

export const DecorativeFormsRow = ({ className }: { className?: string }) => (
  <div className={cn('flex flex-col items-center gap-y-12 py-8', className)}>
    <div className="flex flex-wrap justify-center gap-x-24 gap-y-12">
      <div className="flex items-center gap-8">
        <div className="bg-accent-black h-30 w-30 flex items-center justify-center rounded-full">
          <FileCheck className="text-accent-white h-15 w-15 -mt-1" />
        </div>
        <Body2 className="text-text-secondary text-pretty">
          Automated Form 8949 and Schedule D
        </Body2>
      </div>
      <div className="flex items-center gap-8">
        <div className="bg-accent-black h-30 w-30 flex items-center justify-center rounded-full">
          <FileSearch className="text-accent-white h-15 w-15 -mr-1 -mt-1" />
        </div>
        <Body2 className="text-text-secondary text-pretty">
          Free report preview
        </Body2>
      </div>
    </div>
  </div>
);

function TaxPartnerRow({ partnerSlug }: { partnerSlug?: string }) {
  switch (partnerSlug) {
    case 'hrblock':
      return (
        <div className="flex items-center gap-8">
          <div className="flex">
            <HRBlockIcon className="h-30 w-30 rounded-full" />
          </div>
          <Body2 className="text-text-secondary text-pretty">
            File directly with H&R Block
          </Body2>
        </div>
      );
    case 'turbotax':
      return (
        <div className="flex items-center gap-8">
          <div className="flex">
            <TurboTaxIcon className="h-30 w-30 rounded-full" />
          </div>
          <Body2 className="text-text-secondary text-pretty">
            File directly with TurboTax
          </Body2>
        </div>
      );
    default:
      return (
        <div className="flex items-center gap-8">
          <div className="flex">
            <TurboTaxIcon className="h-30 w-30 rounded-full" />
            <HRBlockIcon className="h-30 w-30 -ml-[6px] rounded-full" />
          </div>
          <Body2 className="text-text-secondary text-pretty">
            File directly with TurboTax and H&R Block
          </Body2>
        </div>
      );
  }
}
